@font-face {
  font-family: 'minecraft_fiftysolid';
  src: url('../fonts/minecraftfifty-solid-webfont.woff2') format('woff2'),
  url('../fonts/minecraftfifty-solid-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

h1 {
  font-family: 'minecraft_fiftysolid';
  font-size: 48px;
  line-height: 46px;
  align-items: center;
  text-align: center;
  letter-spacing: 2px;
  line-height: 60px;
  span {
    color: #F8A700;
  }
}

h2 {
  color: #A1ABBD;
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
  text-align: center;


  ol {
    margin: auto;
    margin-top:30px;
    max-width: 400px;
    font-size: 18px;
    line-height: 25px;
    li {
      text-align: left;
    }
  }

  @media only screen and (max-width: 500px) {
    font-size: 20px;
    padding: 20px;

    ol {
      font-size: 14px;
    }
  }
}

#site-footer {
  margin-top: 50px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 14px;
  padding-bottom: 15px;

  .w-info {
    padding-bottom: 15px;
    p {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
  a {
    color: #F8A700;
    text-decoration: none;
    &:visited {
      color: #F8A700;
    }
  }
}

.mainWrapper {
  margin: auto;
  display: flex;
  align-content: space-evenly;
  flex-direction: row;
  max-width: 1340px;
  margin: auto;
  flex-wrap: wrap;
  background-color: #101114;
  margin-top: 80px;
  padding-bottom: 40px;
}

.flexItem {
  text-align: center;
  flex-grow: 1;
  margin: auto;

  &.right {
    width: 100%;
    max-width: 600px;
    flex-grow: 0;
    margin: auto;
  }
}

#container {
  margin-top: 10px;
}


.currencies, #otherCurrencies {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 400px;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 0px;
  flex-wrap: wrap;

  a {
    border: solid 2px transparent;
  }
  a:active {
    border: solid 2px #FFF;
    border-radius: 50%;
  }
  a:focus {
    border: solid 2px #FFF;
    border-radius: 50%;
  }

  .coin {
    border: solid 2px transparent;
    cursor: pointer;
    padding: 4px;
    width: 44px;
    height: 44px;
    flex-grow: 0;
    flex-shrink: 0;

    &.empty {
      width: 48px;
      height: 48px;
      cursor: auto;
      border: solid 4px transparent;
    }

    &:target {
      border: solid 2px #FFF;
      border-radius: 50%;
    }

    img {
      width: 32px;
      height: 32px;
    }

    &#more {
      width: 50px;
      height: 50px;
      padding: 0px;
      border: solid 4px transparent;

      .morebg {
        width: 34px;
        height: 34px;
        margin: 4px;
        background: #5a5a5a;
        border-radius: 50%;
        letter-spacing: -2px;
        font-weight: bold;
        line-height: 23px;
      }
    }
  }
}

#otherCurrencies {
  display: none;
  margin-top: 0px;
}

.coins {
  margin-top:30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  display: none;

  &.selected {
    display: flex;
  }

  .coin {
    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 0;
    img {
      max-width: 60px;
    }
  }
}

.float-left {
  float: left;
}

.float-right {
  float: right
}

.slider {
  margin: 0px;
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 5px;
  background: #3a3a3a;
  outline: none;
  opacity: 1;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  z-index: 2;
  position: relative;
  display: block;
}

.rotationSlides {
  margin-top: 40px;
  margin-bottom: 70px;
}

.half-slider {
  width: 45%
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #F8A700;
  cursor: pointer;
  border: solid 2px #FFF;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fbc300;
  cursor: pointer;
}
label {
  font-weight: bold;
  margin: 10px;
  display: block;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

}

.inputfile + label {
  width: 220px;
  padding-right: 10px;
  cursor: pointer;
  display: inline-block;
  color: #FFF;
  background-color: #F8A700;
  border-radius: 30px;
  margin-top: 30px;
}
.inputfile + label img {
  width: 100%;
  height: 100%;
  fill: #ffffff;
}

.inputfile + label div {
  width: 40px;
  height: 40px;
  display: inline-block;
  padding: 5px;
  margin: 10px;
}

#save {
  width: 220px;
  padding-right: 10px;
  cursor: pointer;
  display: inline-block;
  color: #FFF;
  background-color: #F8A700;
  border-radius: 30px;
  border: none;
  font-weight: bold;
  margin-top: 30px;
}
#save img {
  width: 100%;
  height: 100%;
  fill: #ffffff;
}

#save div {
  width: 40px;
  height: 40px;
  display: inline-block;
  padding: 5px;
  margin: 10px;
}

.konvajs-content {
  margin: auto
}
